<template>
  <item-wrapper>
    <item-header :title="t('faqs.title')" />
    <main>
      <div class="wrapper">
        <div class="left-content content">
          <div class="content-item" v-for="item in leftContent" :key="item">
            <div class="content-title" @click="toggleLeft(item)">
              <img
                class="left"
                src="@/assets/icons/faqs/about.svg"
                alt="logo"
              />
              <p>{{ item.title }}</p>
              <img
                class="right"
                src="@/assets/icons/faqs/hide.svg"
                alt="hidden"
                v-if="!item.toggle"
              />
              <img
                class="right"
                src="@/assets/icons/faqs/show.svg"
                alt="show"
                v-else
              />
            </div>
            <div class="details" v-if="item.toggle" v-html="item.content" />
          </div>
        </div>
        <div class="right-content content">
          <div class="content-item" v-for="item in rightContent" :key="item">
            <div class="content-title" @click="toggleRight(item)">
              <img
                class="left"
                src="@/assets/icons/faqs/about.svg"
                alt="logo"
              />
              <p>{{ item.title }}</p>
              <img
                class="right"
                src="@/assets/icons/faqs/hide.svg"
                alt="hidden"
                v-if="!item.toggle"
              />
              <img
                class="right"
                src="@/assets/icons/faqs/show.svg"
                alt="show"
                v-else
              />
            </div>
            <div class="details" v-if="item.toggle" v-html="item.content" />
          </div>
        </div>
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent, reactive } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

type FaqsData = { toggle: boolean; title: string; content: string };
export default defineComponent({
  name: 'Faqs',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(),
  },
  setup() {
    const { t } = useI18n();

    const leftContent = reactive([
      {
        toggle: false,
        title: t('faqs.question1'),
        content: `<p>${t('faqs.answer1')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question2'),
        content: `<p>${t('faqs.answer2')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question3'),
        content: `<p>${t('faqs.answer3')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question4'),
        content: `<p>${t('faqs.answer4')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question5'),
        content: `<p>${t('faqs.answer5')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question6'),
        content: `<p>${t('faqs.answer6', {
          email: 'info@rightphone.com',
        })}</p> `,
      },
    ]);
    const rightContent = reactive([
      {
        toggle: false,
        title: t('faqs.question7'),
        content: `<p>${t('faqs.answer7', {
          email: 'info@rightphone.com',
        })}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question8'),
        content: `<p>${t('faqs.answer8')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question9'),
        content: `<p>${t('faqs.answer9')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question10'),
        content: `<p>${t('faqs.answer10')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question11'),
        content: `<p>${t('faqs.answer11')}</p> `,
      },
    ]);
    const toggleLeft = (item: FaqsData) => {
      leftContent[leftContent.indexOf(item)].toggle = !leftContent[
        leftContent.indexOf(item)
      ].toggle;
    };
    const toggleRight = (item: FaqsData) => {
      rightContent[rightContent.indexOf(item)].toggle = !rightContent[
        rightContent.indexOf(item)
      ].toggle;
    };
    return {
      leftContent,
      rightContent,
      toggleLeft,
      toggleRight,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

main {
  @include flex-center();
  .wrapper {
    display: flex;
    width: 1280px;
    > div {
      width: 640px;
      padding: 0 72.5px;
    }
    .content {
      .content-item {
        .content-title {
          @include flex-center();
          justify-content: space-between;
          width: 100%;
          margin-bottom: 86px;
          cursor: pointer;

          p {
            font-size: 16px;
            font-weight: 400;
            color: $font-color;
            line-height: 20px;
            padding: 0 15px;
            margin: 0;
            flex: 1;
          }

          img {
            &.left {
              width: 24px;
              height: 24px;
            }

            &.right {
              width: 17px;
              height: 17px;
            }
          }
        }

        .details {
          margin: -60px 0 64px 40px;
          font-size: 15px;
          :deep(a) {
            color: #0000ee;
            &:active {
              color: #ff0000;
            }
          }
        }
      }
    }
  }
}
</style>
