
import importComponents from '@/utils/import-components';
import { defineComponent, reactive } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

type FaqsData = { toggle: boolean; title: string; content: string };
export default defineComponent({
  name: 'Faqs',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(),
  },
  setup() {
    const { t } = useI18n();

    const leftContent = reactive([
      {
        toggle: false,
        title: t('faqs.question1'),
        content: `<p>${t('faqs.answer1')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question2'),
        content: `<p>${t('faqs.answer2')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question3'),
        content: `<p>${t('faqs.answer3')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question4'),
        content: `<p>${t('faqs.answer4')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question5'),
        content: `<p>${t('faqs.answer5')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question6'),
        content: `<p>${t('faqs.answer6', {
          email: 'info@rightphone.com',
        })}</p> `,
      },
    ]);
    const rightContent = reactive([
      {
        toggle: false,
        title: t('faqs.question7'),
        content: `<p>${t('faqs.answer7', {
          email: 'info@rightphone.com',
        })}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question8'),
        content: `<p>${t('faqs.answer8')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question9'),
        content: `<p>${t('faqs.answer9')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question10'),
        content: `<p>${t('faqs.answer10')}</p> `,
      },
      {
        toggle: false,
        title: t('faqs.question11'),
        content: `<p>${t('faqs.answer11')}</p> `,
      },
    ]);
    const toggleLeft = (item: FaqsData) => {
      leftContent[leftContent.indexOf(item)].toggle = !leftContent[
        leftContent.indexOf(item)
      ].toggle;
    };
    const toggleRight = (item: FaqsData) => {
      rightContent[rightContent.indexOf(item)].toggle = !rightContent[
        rightContent.indexOf(item)
      ].toggle;
    };
    return {
      leftContent,
      rightContent,
      toggleLeft,
      toggleRight,
      t,
    };
  },
});
